import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as ROUTES from '../../constants/routes'
import Logo from '../Navigation/Logo'

const SignInWrapper = ({ children }) => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-sm w-full">
      <div>
        <Link to={ROUTES.LANDING}>
          <Logo className='w-64 mx-auto' />
        </Link>
      </div>

      {children}
    </div>
  </div>
)

SignInWrapper.propTypes = {
  children: PropTypes.node,
}

export default SignInWrapper