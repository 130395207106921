import React from 'react';

import SignInWrapper from '../components/Containers/SignInWrapper';
import Layout from '../components/layout';
import PasswordForgetForm from '../components/PasswordForget';

const PasswordForgetPage = () => (
  <SignInWrapper>
    <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-800">
      Forgot Password
    </h1>

    <PasswordForgetForm />
  </SignInWrapper>
);

export default () => (
  <Layout>
    <PasswordForgetPage />
  </Layout>
);
